export const colors = {
  blue: '#3eb0ef',
  green: '#a4d037',
  purple: '#ad26b4',
  yellow: '#fecd35',
  red: '#f05230',
  darkgrey: '#333',
  midgrey: '#707279',
  lightgrey: '#c5d2d9',
  whitegrey: '#e5eff5',
  pink: '#D0C1C4',
  brown: '#a3821a',
  darkmode: '#f3f1f0',

  main: '#B6A79A',
  sand: '#E8E1D8',
  white: '#ffffff',
};
